<template>
  <div style="width: 100%">
    <!--        <div class="widget_content" v-if="pgame != null && pgame!=undefined" :class="{'inplay_bg_soccer':game.typeId === inplayConst.SPORTS_ID_SOCCER
                            ,'inplay_bg_volleyball':game.typeId  === inplayConst.SPORTS_ID_VOLLEYBALL
                            ,'inplay_bg_baseball':game.typeId  === inplayConst.SPORTS_ID_BASEBALL
                            ,'inplay_bg_bascketball':game.typeId  === inplayConst.SPORTS_ID_BASKETBALL
                            ,'inplay_bg_hockey':game.typeId  === inplayConst.SPORTS_ID_ICEHOKEY}">-->
    <div class="widget_content" v-if="pgame != null && pgame!=undefined">
      <div class="team_info">
        <div class="tin" style="text-align: right;">{{ homeName }}</div>
        <div class="tiimg" style="text-align: center;">
          <img alt="" v-if="game.homeImgId != undefined"
               :src="inplayConst.TEAM_IMAG + game.homeImgId + '.png'">
        </div>
        <div class="score">{{ homeScore }}</div>
        <div class="daxi">
         -
        </div>
        <div class="score">{{ awayScore }}</div>
        <div class="tiimg" style="text-align: center;">
          <img alt="" v-if="game.awayImgId != undefined"
               :src="inplayConst.TEAM_IMAG + game.awayImgId + '.png'">
        </div>
        <div class="tin" style="text-align: left;padding-left: 1rem">{{ awayName }}</div>
      </div>
      <!--축구스코어-->

      <div class="common_scores" v-if="game.typeId === inplayConst.SPORTS_ID_SOCCER">
        <div class="line bb">
          <div class="tn">&nbsp</div>
          <div class="sc">
            <div>전반전</div>
            <div>후반전</div>
            <div>득점타임</div>
          </div>
        </div>
        <div class="line">
          <div class="tn">{{ homeName }}</div>
          <div class="sc">
            <div>{{ scores.soccerHalf1HomeScore }}</div>
            <div>{{ scores.soccerHalf2HomeScore }}</div>
            <div class="goaltime">
              <span v-for="(gole,idx) in game.goalsList" :key="idx" v-if="gole.team === 1 ">{{ gole.time }}'</span>

            </div>
          </div>
        </div>
        <div class="line">
          <div class="tn">{{ awayName }}</div>
          <div class="sc">
            <div>{{ scores.soccerHalf1AwayScore }}</div>
            <div>{{ scores.soccerHalf2AwayScore }}</div>
            <div class="goaltime">
               <span v-for="(gole,idx) in game.goalsList" :key="idx" v-if="gole.team === 2 ">{{ gole.time }}'</span>
            </div>
          </div>
        </div>
      </div>


      <!--야구,농구,배구,하키스코어-->
      <div class="common_scores" v-if="game.typeId === inplayConst.SPORTS_ID_BASEBALL">
        <div class="line bb">
          <div class="tn">&nbsp</div>
          <div class="sc">
            <div>1</div>
            <div>2</div>
            <div>3</div>
            <div>4</div>
            <div>5</div>
            <div>6</div>
            <div>7</div>
            <div>8</div>
            <div>9</div>
            <div v-if="halfStatus>=10">10</div>
            <div v-if="halfStatus>=11">11</div>
            <div v-if="halfStatus>=12">12</div>
            <div v-if="halfStatus>=13">13</div>
            <div v-if="halfStatus>=14">14</div>
            <div v-if="halfStatus>=15">15</div>
            <div v-if="halfStatus>=16">16</div>
            <div v-if="halfStatus>=17">17</div>
            <div v-if="halfStatus>=18">18</div>
            <div v-if="halfStatus>=19">19</div>
            <div v-if="halfStatus>=20">20</div>
            <div>H</div>
          </div>
        </div>
        <div class="line">
          <div class="tn">{{ homeName }}</div>
          <div class="sc">
            <div>{{ scores.baseball1NHomeScore }}</div>
            <div>{{ halfStatus >= 2 ? scores.baseball2NHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 3 ? scores.baseball3NHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 4 ? scores.baseball4NHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 5 ? scores.baseball5NHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 6 ? scores.baseball6NHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 7 ? scores.baseball7NHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 8 ? scores.baseball8NHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 9 ? scores.baseball9NHomeScore : '-' }}</div>
            <div v-if="halfStatus>=10">{{ scores.baseball10NHomeScore }}</div>
            <div v-if="halfStatus>=11">{{ scores.baseball11NHomeScore }}</div>
            <div v-if="halfStatus>=12">{{ scores.baseball12NHomeScore }}</div>
            <div v-if="halfStatus>=13">{{ scores.baseball13NHomeScore }}</div>
            <div v-if="halfStatus>=14">{{ scores.baseball14NHomeScore }}</div>
            <div v-if="halfStatus>=15">{{ scores.baseball15NHomeScore }}</div>
            <div v-if="halfStatus>=16">{{ scores.baseball16NHomeScore }}</div>
            <div v-if="halfStatus>=17">{{ scores.baseball17NHomeScore }}</div>
            <div v-if="halfStatus>=18">{{ scores.baseball18NHomeScore }}</div>
            <div v-if="halfStatus>=19">{{ scores.baseball19NHomeScore }}</div>
            <div v-if="halfStatus>=20">{{ scores.baseball20NHomeScore }}</div>
            <div class="hits">{{ game.homeHits }}</div>
          </div>
        </div>
        <div class="line">
          <div class="tn">{{ awayName }}</div>
          <div class="sc">
            <div>{{ scores.baseball1NAwayScore }}</div>
            <div>{{ halfStatus >= 2 ? scores.baseball2NAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 3 ? scores.baseball3NAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 4 ? scores.baseball4NAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 5 ? scores.baseball5NAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 6 ? scores.baseball6NAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 7 ? scores.baseball7NAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 8 ? scores.baseball8NAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 9 ? scores.baseball9NAwayScore : '-' }}</div>
            <div v-if="halfStatus>=10">{{ scores.baseball10NAwayScore }}</div>
            <div v-if="halfStatus>=11">{{ scores.baseball11NAwayScore }}</div>
            <div v-if="halfStatus>=12">{{ scores.baseball12NAwayScore }}</div>
            <div v-if="halfStatus>=13">{{ scores.baseball13NAwayScore }}</div>
            <div v-if="halfStatus>=14">{{ scores.baseball14NAwayScore }}</div>
            <div v-if="halfStatus>=15">{{ scores.baseball15NAwayScore }}</div>
            <div v-if="halfStatus>=16">{{ scores.baseball16NAwayScore }}</div>
            <div v-if="halfStatus>=17">{{ scores.baseball17NAwayScore }}</div>
            <div v-if="halfStatus>=18">{{ scores.baseball18NAwayScore }}</div>
            <div v-if="halfStatus>=19">{{ scores.baseball19NAwayScore }}</div>
            <div v-if="halfStatus>=20">{{ scores.baseball20NAwayScore }}</div>
            <div class="hits">{{ game.awayHits }}</div>
          </div>
        </div>
      </div>
      <div class="common_scores" v-if="game.typeId === inplayConst.SPORTS_ID_BASKETBALL">
        <div class="line bb">
          <div class="tn">&nbsp</div>
          <div class="sc">
            <div>1Q</div>
            <div>2Q</div>
            <div>3Q</div>
            <div>4Q</div>
          </div>
        </div>
        <div class="line">
          <div class="tn">{{ homeName }}</div>
          <div class="sc">
            <div>{{ scores.basketball1QHomeScore }}</div>
            <div>{{ halfStatus >= 2 ? scores.basketball2QHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 3 ? scores.basketball3QHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 4 ? scores.basketball4QHomeScore : '-' }}</div>
            <div v-if="halfStatus>4">{{ scores.basketball5QHomeScore }}</div>
          </div>
        </div>
        <div class="line">
          <div class="tn">{{ awayName }}</div>
          <div class="sc">
            <div>{{ scores.basketball1QAwayScore }}</div>
            <div>{{ halfStatus >= 2 ? scores.basketball2QAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 3 ? scores.basketball3QAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 4 ? scores.basketball4QAwayScore : '-' }}</div>
            <div v-if="halfStatus>4">{{ scores.basketball4QAwayScore }}</div>
          </div>
        </div>
      </div>
      <div class="common_scores" v-if="game.typeId === inplayConst.SPORTS_ID_ICEHOKEY">
        <div class="line bb">
          <div class="tn">&nbsp</div>
          <div class="sc">
            <div>1P</div>
            <div>2P</div>
            <div>3P</div>
          </div>
        </div>
        <div class="line">
          <div class="tn">{{ homeName }}</div>
          <div class="sc">
            <div>{{ scores.iceHockey1PHomeScore }}</div>
            <div>{{ halfStatus >= 2 ? scores.iceHockey2PHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 3 ? scores.iceHockey3PHomeScore : '-' }}</div>
          </div>
        </div>
        <div class="line">
          <div class="tn">{{ awayName }}</div>
          <div class="sc">
            <div>{{ scores.iceHockey1PAwayScore }}</div>
            <div>{{ halfStatus >= 2 ? scores.iceHockey2PAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 3 ? scores.iceHockey3PAwayScore : '-' }}</div>
          </div>
        </div>
      </div>
      <div class="common_scores" v-if="game.typeId === inplayConst.SPORTS_ID_VOLLEYBALL">
        <div class="line bb">
          <div class="tn">&nbsp</div>
          <div class="sc">
            <div>1Set</div>
            <div>2Set</div>
            <div>3Set</div>
            <div>4Set</div>
            <div>5Set</div>
          </div>
        </div>
        <div class="line">
          <div class="tn">{{ homeName }}</div>
          <div class="sc">
            <div>{{ scores.volleyball1SHomeScore }}</div>
            <div>{{ halfStatus >= 2 ? scores.volleyball2SHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 3 ? scores.volleyball3SHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 4 ? scores.volleyball4SHomeScore : '-' }}</div>
            <div>{{ halfStatus >= 5 ? scores.volleyball5SHomeScore : '-' }}</div>
          </div>
        </div>
        <div class="line">
          <div class="tn">{{ awayName }}</div>
          <div class="sc">
            <div>{{ scores.volleyball1SAwayScore }}</div>
            <div>{{ halfStatus >= 2 ? scores.volleyball2SAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 3 ? scores.volleyball3SAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 4 ? scores.volleyball4SAwayScore : '-' }}</div>
            <div>{{ halfStatus >= 5 ? scores.volleyball5SAwayScore : '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%">
      <!--<div style="display: flex;justify-content: space-between">
          <button class="btn02 bw2" style="line-height: 28px"
                  v-if="game != null && game.trackerUrl != null && game.trackerUrl !== 'none' && game.videoUrl!=null  && game.videoUrl !== 'none'"
                  @click="changeShowType(1)">
              트래커
          </button>
          <button class="btn02 bw2 pt3" style="line-height: 28px"
                  v-if="game != null && game.videoUrl!=null  && game.videoUrl !== 'none'"
                  @click="changeShowType(2)">
              동영상
          </button>
      </div>-->
      <div v-if="game != null && game.trackerUrl != null && game.trackerUrl !== 'none' && showType===1">
        <iframe class="tracker" scrolling="no" frameborder="0"
                :src="game.trackerUrl"></iframe>
      </div>
    </div>


  </div>


</template>

<script>

import inplayConst from "@/common/inplayConst";

export default {
  name: "InplayScoreComp",
  components: {},
  props: {
    pgame: {
      type: Object,
      default() {
        return null
      }
    },
    homeName: {
      type: String,
      default() {
        return ''
      }
    },
    awayName: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      game: null,
      inplayConst,
      homeScore: 0,
      awayScore: 0,
      half1Score: 0,
      half2Score: 0,
      scores: {},
      halfStatus: 1,
      playerOptions: {
        autoplay: false,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false
        }
      },
      videoTimer: null,
      showType: 1,
      videoUrl: null,
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  created() {
    this.showType = 1
  },
  watch: {
    pgame: {
      handler() {
        if (this.pgame != null) {
          this.game = this.pgame
          try {
            this.halfStatus = this.game.halfStatus
            if (this.game.scores === "{}") {
              this.homeScore = 0
              this.awayScore = 0
              return 0;
            }
            this.scores = JSON.parse(this.game.scores)
            if (this.game.typeId === inplayConst.SPORTS_ID_SOCCER) {
              let scores = this.getFulltimeSoccerScore(this.scores)
              this.homeScore = scores.homeScore
              this.awayScore = scores.awayScore
            }
            if (this.game.typeId === inplayConst.SPORTS_ID_BASKETBALL) {
              let scores = this.getFulltimeBascketballScore(this.scores)
              this.homeScore = scores.homeScore
              this.awayScore = scores.awayScore
            }
            if (this.game.typeId === inplayConst.SPORTS_ID_BASEBALL) {
              let scores = this.getFulltimeBaseballScore(this.scores)
              this.homeScore = scores.homeScore
              this.awayScore = scores.awayScore
            }
            if (this.game.typeId === inplayConst.SPORTS_ID_VOLLEYBALL) {
              let scores = this.getFulltimeVolleyballScore(this.scores, this.game.halfStatus)
              this.homeScore = scores.homeScore
              this.awayScore = scores.awayScore
            }
            if (this.game.typeId === inplayConst.SPORTS_ID_ICEHOKEY) {
              let scores = this.getFulltimeIceHockeyScore(this.scores, this.game.halfStatus)
              this.homeScore = scores.homeScore
              this.awayScore = scores.awayScore
            }
          } catch (e) {

          }
        }
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    changeShowType(type, videoUrl) {
      this.showType = type;
      if (type === 2) {
        //videoUrl = 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8'
        //videoUrl = 'https://vcdn-002.fastedge.net/livesoft/00/playlist.m3u8'
        this.playVideo(videoUrl)
        this.player.play()
      }
    },
    getFulltimeSoccerScore(scores) {
      let hsc = 0
      let asc = 0;
      hsc += scores.soccerHalf1HomeScore
      hsc += scores.soccerHalf2HomeScore
      asc += scores.soccerHalf1AwayScore
      asc += scores.soccerHalf2AwayScore
      return {
        homeScore: hsc,
        awayScore: asc,
      }
    },
    getFulltimeVolleyballScore(scores, halfStatus) {
      let hsc = 0
      let asc = 0;
      if (halfStatus > 1) {
        if (scores.volleyball1SHomeScore > scores.volleyball1SAwayScore) {
          hsc += 1;
        }
        if (scores.volleyball1SHomeScore < scores.volleyball1SAwayScore) {
          asc += 1;
        }
      }
      if (halfStatus > 2) {
        if (scores.volleyball2SHomeScore > scores.volleyball2SAwayScore) {
          hsc += 1;
        }
        if (scores.volleyball2SHomeScore < scores.volleyball2SAwayScore) {
          asc += 1;
        }
      }
      if (halfStatus > 3) {
        if (scores.volleyball3SHomeScore > scores.volleyball3SAwayScore) {
          hsc += 1;
        }
        if (scores.volleyball3SHomeScore < scores.volleyball3SAwayScore) {
          asc += 1;
        }
      }
      if (halfStatus > 4) {
        if (scores.volleyball4SHomeScore > scores.volleyball4SAwayScore) {
          hsc += 1;
        }
        if (scores.volleyball4SHomeScore < scores.volleyball4SAwayScore) {
          asc += 1;
        }
      }
      if (halfStatus >= 5) {
        if (scores.volleyball5SHomeScore >= 15 || scores.volleyball5SAwayScore >= 15) {
          let flag = Math.abs(scores.volleyball5SHomeScore - scores.volleyball5SAwayScore) >= 2
          if (flag) {
            if (scores.volleyball5SHomeScore > scores.volleyball5SAwayScore) {
              hsc += 1;
            }
            if (scores.volleyball5SHomeScore < scores.volleyball5SAwayScore) {
              asc += 1;
            }
          }
        }

      }
      return {
        homeScore: hsc,
        awayScore: asc
      }
    },
    getFulltimeBascketballScore(scores) {
      let hsc = 0
      let asc = 0;
      hsc += scores.basketball1QHomeScore
      hsc += scores.basketball2QHomeScore
      hsc += scores.basketball3QHomeScore
      hsc += scores.basketball4QHomeScore
      hsc += scores.basketball5QHomeScore
      asc += scores.basketball1QAwayScore
      asc += scores.basketball2QAwayScore
      asc += scores.basketball3QAwayScore
      asc += scores.basketball4QAwayScore
      asc += scores.basketball5QAwayScore
      return {
        homeScore: hsc,
        awayScore: asc
      }
    },
    getFulltimeIceHockeyScore(scores) {
      let hsc = 0
      let asc = 0;
      hsc += scores.iceHockey1PHomeScore
      hsc += scores.iceHockey2PHomeScore
      hsc += scores.iceHockey3PHomeScore

      asc += scores.iceHockey1PAwayScore
      asc += scores.iceHockey2PAwayScore
      asc += scores.iceHockey3PAwayScore
      return {
        homeScore: hsc,
        awayScore: asc
      }
    },
    getFulltimeBaseballScore(scores) {
      let hsc = 0
      let asc = 0;
      hsc += scores.baseball1NHomeScore
      hsc += scores.baseball2NHomeScore
      hsc += scores.baseball3NHomeScore
      hsc += scores.baseball4NHomeScore
      hsc += scores.baseball5NHomeScore
      hsc += scores.baseball6NHomeScore
      hsc += scores.baseball7NHomeScore
      hsc += scores.baseball8NHomeScore
      hsc += scores.baseball9NHomeScore
      hsc += scores.baseball10NHomeScore
      hsc += scores.baseball11NHomeScore
      hsc += scores.baseball12NHomeScore
      hsc += scores.baseball13NHomeScore
      hsc += scores.baseball14NHomeScore
      hsc += scores.baseball15NHomeScore
      hsc += scores.baseball16NHomeScore
      hsc += scores.baseball17NHomeScore
      hsc += scores.baseball18NHomeScore
      hsc += scores.baseball19NHomeScore
      hsc += scores.baseball20NHomeScore

      asc += scores.baseball1NAwayScore
      asc += scores.baseball2NAwayScore
      asc += scores.baseball3NAwayScore
      asc += scores.baseball4NAwayScore
      asc += scores.baseball5NAwayScore
      asc += scores.baseball6NAwayScore
      asc += scores.baseball7NAwayScore
      asc += scores.baseball8NAwayScore
      asc += scores.baseball9NAwayScore
      asc += scores.baseball10NAwayScore
      asc += scores.baseball11NAwayScore
      asc += scores.baseball12NAwayScore
      asc += scores.baseball13NAwayScore
      asc += scores.baseball14NAwayScore
      asc += scores.baseball15NAwayScore
      asc += scores.baseball16NAwayScore
      asc += scores.baseball17NAwayScore
      asc += scores.baseball18NAwayScore
      asc += scores.baseball19NAwayScore
      asc += scores.baseball20NAwayScore
      return {
        homeScore: hsc,
        awayScore: asc
      }
    },
    onPlayerPlay(player) {
      //console.log('player play!', player)
    },
    onPlayerReady(player) {
      console.log('player ready!', player)
      //this.player.play()
    },
    playVideo: function (source) {
      console.log('play video...')
      const video = {
        withCredentials: false,
        type: 'application/x-mpegurl',
        src: source
      }
      this.player.reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(source)
    }

  },
  mounted() {

  },
  beforeDestroy() {

  },
  destroyed() {
  }
}
</script>

<style scoped>
.player {
  position: relative !important;
  width: 100%;
}

.video-js {
  text-align: center !important;
}

.vjs-custom-skin {
  height: 400px !important;
  margin-left: 5px !important;
}

.vjs-custom-skin /deep/ .video-js {
  width: 650px;
  height: 400px;
}


.tracker {
  width: 100%;
  height: 100vh;
}

@media screen and (min-width: 300px) and (max-width: 800px) {
  .vjs-custom-skin /deep/ .video-js {
    width: 370px !important;
    height: 300px;
  }

  .vjs-custom-skin {
    height: 300px !important;
  }

  .tracker {
    height: 250px;
  }
}

@media (min-width: 1024px) {
  .tracker {
    height: 240px;
  }
}

@media (min-width: 1100px) {
  .tracker {
    height: 260px;
  }
}

/*>=1100的设备*/
@media (min-width: 1280px) {
  .tracker {
    height: 240px;
  }
}

/*>=1280的设备*/

@media (min-width: 1366px) {
  .tracker {
    height: 260px;
  }
}

@media (min-width: 1440px) {
  .tracker {
    height: 300px;
  }
}

@media (min-width: 1600px) {
  .tracker {
    height: 340px;
  }
}

@media (min-width: 1680px) {
  .tracker {
    height: 360px;
  }
}

@media (min-width: 1768px) {
  .tracker {
    height: 400px;
  }
}

@media (min-width: 1920px) {
  .tracker {
    height: 420px;
  }
}

@media (min-width: 2560px) {
  .tracker {
    height: 600px;
  }
}


</style>